<template>
  <v-card>
    <v-card-title class="text-h6">
      <div>
        {{ $t("labels.change_item_order_multiple") }}
        <span class="grey--text fs-14px ml-1">
          {{ $t("labels.sum_order", { count: countOrder }) }}
        </span>
      </div>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabled"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-title>

    <!-- <v-card-text v-if="errMsg">
      <div class="error--text fs-14px font-italic">
        {{ errMsg }}
      </div>
    </v-card-text> -->

    <v-card-text>
      <div>
        <div class="mb-3">
          <v-textarea
            v-model="codes"
            class="c-input-small"
            rows="5"
            label="Tracking | Mã đơn | Mã vận chuyển"
            placeholder="Tracking1;Tracking2;Tracking3;Tracking4;..."
            autofocus
            dense
            outlined
            clearable
            hide-details
            @paste.prevent.stop="onUidPaste"
            @change="preventOrder"
          ></v-textarea>
        </div>

        <div class="mb-3" v-if="allowCodes.length > 0 || wrongCodes.length > 0">
          <v-simple-table class="table-padding-2">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td
                    class="success--text font-weight-medium"
                    style="width: 100px"
                  >
                    {{ $t("labels.order_allow_change") }}
                  </td>
                  <td
                    class="success--text font-weight-medium text-center"
                    style="width: 50px"
                  >
                    {{ allowCodes.length }}
                  </td>
                  <td class="success--text text-center" style="width: 50px">
                    <span
                      @click="copyText(`${allowCodes.join(';')}`)"
                      class="cursor-pointer primary--text text-decoration-underline"
                    >
                      Copy
                    </span>
                  </td>
                  <td>{{ allowCodes.join("; ") }}</td>
                </tr>
                <tr>
                  <td class="error--text font-weight-medium">
                    {{ $t("labels.order_wrong_change") }}
                  </td>
                  <td class="error--text font-weight-medium text-center">
                    {{ wrongCodes.length }}
                  </td>
                  <td class="error--text text-center">
                    <span
                      @click="copyText(`${wrongCodes.join(';')}`)"
                      class="cursor-pointer primary--text text-decoration-underline"
                    >
                      Copy
                    </span>
                  </td>
                  <td>{{ wrongCodes.join("; ") }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div>
          <v-simple-table
            fixed-header
            height="calc(100vh - 500px)"
            class="table-padding-2"
          >
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" colspan="6">
                    <span class="purple--text fs-14px">
                      {{ $t("labels.all_item_change") }}
                    </span>
                  </th>
                </tr>
                <tr>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 250px"
                  >
                    {{ $t("labels.barcode") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 80px"
                  >
                    {{ $t("labels.goods") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="min-width: 80px"
                  >
                    {{ $t("labels.goods_description") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 82px"
                  >
                    {{ $t("labels.size") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 100px"
                  >
                    {{ $t("labels.quantity") }}
                  </th>
                  <th
                    role="columnheader"
                    class="text-center"
                    style="width: 100px"
                  >
                    <v-btn color="primary" @click="addRow">
                      {{ $t("labels.add") }}
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-center"
                  v-for="(item, index) in items"
                  :key="`di_${index}`"
                >
                  <td>
                    <SelectSuggestGoodsLimit
                      :index="index"
                      @onSelect="selectItem"
                    />
                  </td>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.description }}
                  </td>
                  <td>
                    {{ item.size }}
                  </td>
                  <td>
                    <v-text-field
                      v-model.number="item.quantity"
                      class="c-input-xs my-1"
                      type="number"
                      min="1"
                      :label="$t('labels.quantity')"
                      dense
                      outlined
                      hide-details
                      single-line
                    ></v-text-field>
                  </td>
                  <td>
                    <v-btn small color="error" @click="delRow(index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash";

export default {
  name: "ChangeItemOrderMultiple",
  components: {
    SelectSuggestGoodsLimit: () =>
      import("@/components/common/SelectSuggestGoodsLimit"),
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    items: [],
    wrongCodes: [],
    allowCodes: [],
    codes: "",
    errMsg: "",
  }),
  computed: {
    isDisabled() {
      return !this.items || this.items.length === 0 || !this.codes;
    },
    countOrder() {
      if (!this.codes) {
        return 0;
      }
      const codes = this.codes.split(";");
      return codes.length;
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    addRow() {
      this.items.push({ customer_goods_barcode: "", quantity: 1 });
    },
    delRow(index) {
      this.items = [...this.items].filter((item, i) => i !== index);
    },
    onUidPaste(evt) {
      const value = evt.clipboardData.getData("text");
      if (value.includes("\n")) {
        const codeSplit = value.split("\n").filter((v) => v);
        this.codes = codeSplit.join(";");
      } else if (value.includes(" ")) {
        const codeSplit = value.split(" ").filter((v) => v);
        this.codes = codeSplit.join(";");
      } else {
        this.codes += value;
      }
      // this.preventOrder();
    },

    preventOrder: debounce(async function () {
      if (!this.codes) {
        return false;
      }

      const codes = this.codes.split(";");
      try {
        const { data } = await httpClient.post(
          "/orders-prevent-pickup-multiple",
          {
            codes,
            add_minutes: 30,
          }
        );

        for (let i = 0; i < codes.length; i++) {
          const code = codes[i];
          const order = [...data].find((od) =>
            [
              od.tracking_id,
              od.customer_order_id,
              od.delivery_order_id,
            ].includes(code)
          );
          if (!order) {
            this.wrongCodes.push(code);
          } else {
            this.allowCodes.push(code);
          }
        }

        // this.$vToastify.success(this.$t("messages.prevent_pickup_success"));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.codes = "";
      }
    }, 500),
    async confirm() {
      if (this.isDisabled) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/orders-change-item-multiple", {
          codes: this.allowCodes,
          items: this.items,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.errMsg = errMsg;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    checkDuplicate(item, index) {
      return [...this.items].filter((jtem, jdex) => {
        return (
          jtem.customer_goods_barcode.toUpperCase() ===
            item.customer_goods_barcode.toUpperCase() && index !== jdex
        );
      });
    },
    selectItem({ item, index }) {
      if (!item.customer_goods_barcode) {
        return false;
      }
      const duplicates = this.checkDuplicate(item, index);
      if (duplicates.length > 0) {
        const duplicateCodes = [...duplicates].map(
          (i) => i.customer_goods_barcode
        );
        this.$vToastify.error(
          this.$t("messages.duplicate_barcodes", {
            barcodes: duplicateCodes.join("; "),
          })
        );
        return false;
      }
      const items = [...this.items];
      items[index] = { ...item, quantity: 1 };
      this.items = [...items];
    },
  },
};
</script>

<style scoped></style>
